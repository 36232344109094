@font-face {
  font-family: "soleilbold";
  src: url("../src/assets/fonts/soleilbold-webfont.woff2") format("woff2"),
    url("../src/assets/fonts/soleilbold-webfont.woff") format("woff");
}

@font-face {
  font-family: "soleilbook";
  src: url("../src/assets/fonts/soleilbook-webfont.woff2") format("woff2"),
    url("../src/assets/fonts/soleilbook-webfont.woff") format("woff");
}

@font-face {
  font-family: "soleillight";
  src: url("../src/assets/fonts/soleillight-webfont.woff2") format("woff2"),
    url("../src/assets/fonts/soleillight-webfont.woff") format("woff");
}

@font-face {
  font-family: "soleilregular";
  src: url("../src/assets/fonts/soleilregular-webfont.woff2") format("woff2"),
    url("../src/assets/fonts/soleilregular-webfont.woff") format("woff");
}

html,
body,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1;
  font-family: "soleilregular";
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
img {
  max-width: 100%;
  height: auto;
  display: block;
  border: none;
}
* {
  box-sizing: border-box;
}
a {
  cursor: pointer;
  font-family: "soleilregular" !important;
}

/*hide-input-icon-for Microsoft-Edge  */
input::-ms-reveal,
input::-ms-clear {
  display: none;
}

button {
  cursor: pointer;
  text-transform: capitalize !important;
  font-family: "soleilregular" !important;
  height: 42px;
}
input {
  font-family: "soleilregular" !important;
}
.MuiButton-outlinedError.MuiButtonBase-root:hover {
  color: #fff;
  background-color: #c62828;
  border-color: transparent;
}
.MuiButton-outlinedPrimary.MuiButtonBase-root:hover {
  color: #fff;
  background-color: #0091b2;
  border-color: transparent;
}
.MuiButton-outlinedSuccess.MuiButtonBase-root:hover {
  color: #fff;
  background-color: #2e7d32;
  border-color: transparent;
}

td.status-active,
td.status-send {
  color: #2e7d32 !important;
}
td.status-block,
td.status-resend {
  color: #c62828 !important;
}
textarea {
  font-size: 14px !important;
  font-family: "soleilregular";
}

/* scroll-bar-styling */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-track {
  background: #eee;
}
::-webkit-scrollbar-thumb {
  background: #0091b2;
  border-radius: 6px;
}
.MuiTextField-root {
  width: 100%;
}
.input-error fieldset {
  border-color: #f90505 !important;
}
.input-error label,
label.input-error {
  color: #f90505 !important;
}
.error-msg {
  width: 100%;
  margin-top: 5px;
  display: inline-block;
  font-size: 13px;
  color: #f90505;
  text-align: left;
}
.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary {
  background-color: #008ab4;
}

/* popup-styling */
.MuiDialog-container {
  font-family: "soleilregular" !important;
}
.MuiDialogTitle-root {
  background-color: #0091b2;
  font-size: 16px !important;
  color: #fff;
  font-family: "soleilregular" !important;
}
.MuiDialogTitle-root .MuiSvgIcon-root {
  color: #fff;
}
.btn-col a {
  text-decoration: none;
}

/* Select&Input Styling */
.MuiOutlinedInput-notchedOutline {
  border-color: #00000029 !important;
}
.MuiInputBase-root.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutlin {
  border-color: #00000029 !important;
}
input::placeholder,
textarea::placeholder {
  color: #707070 !important;
}
.select-box .MuiSvgIcon-root {
  color: #707070 !important;
}
.MuiOutlinedInput-input.MuiSelect-select {
  color: #707070 !important;
}
.MuiMenuItem-root {
  font-size: 14px !important;
  font-family: "soleilregular";
}
.MuiButtonBase-root {
  font-size: 14px !important;
  font-family: "soleilregular";
}
.MuiMenuItem-root.MuiMenuItem-gutters.Mui-selected {
  background-color: rgba(255, 255, 255, 0.08);
}
.MuiMenuItem-root.MuiMenuItem-gutters.Mui-selected:hover {
  background-color: rgba(255, 255, 255, 0.12);
}

/* table-styling */
.MuiPaper-root.MuiTableContainer-root {
  border: 1px solid #0091b2;
  border-radius: 5px;
  box-shadow: none;
}
.table-wrapper thead th {
  padding: 8px 15px;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  background: #0091b2;
  white-space: nowrap;
  text-align: left;
}
.table-wrapper td,
.table-wrapper th {
  padding: 10px 15px;
  font-size: 14px;
  color: #0091b2;
  font-weight: 400;
  line-height: 1.4;
}
.table-wrapper th a,
.table-wrapper td a {
  color: #0091b2;
  text-decoration: underline;
  font-weight: 400;
}
.pagination .MuiPaginationItem-root.Mui-selected:hover {
  background-color: #0091b2;
}

.pagination .MuiPaginationItem-root.Mui-selected {
  background-color: #0091b2;
  border-radius: 50% !important;
  color: #fff;
}

/* checkbox-styling */
.MuiButtonBase-root.MuiRadio-root.Mui-checked {
  color: #5282f0 !important;
}
.MuiButtonBase-root.MuiCheckbox-root.Mui-checked {
  color: #5282f0 !important;
}
thead .MuiTableCell-root .MuiButtonBase-root.MuiCheckbox-root.Mui-checked {
  color: #ffffff !important;
}
.MuiCheckbox-root .MuiSvgIcon-root {
  color: #008ab4 !important;
}

/* popop-css */
.popup-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.popup-body .popup-img {
  margin-bottom: 10px;
  filter: invert(54%) sepia(97%) saturate(4148%) hue-rotate(162deg) brightness(88%) contrast(101%);
}
.popup-sub-title {
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #707070;
}
.popup-description {
  margin-bottom: 15px;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #707070;
}
.popup-body .popup-image figure {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
}
.popup-footer {
  padding: 15px;
}
.popup-footer-inner {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px;
}
.popup-footer .btn-col {
  max-width: 50%;
  flex: 0 0 50%;
  height: 42px;
  padding: 0 8px;
}
.popup-footer button {
  width: 100%;
  height: 42px;
}
.no-btn button:hover {
  background-color: #d32f2f;
  color: #fff;
  border-color: transparent;
}
.no-btn button {
  border: 1px solid #d32f2f;
  color: #d32f2f;
}

/* breadcrumb-styling */
.breadcrumb-wrap {
  padding-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.breadcrumb-wrap button:not(:last-child) {
  margin-right: 15px;
}

/* policy-css */
/* .policy-wrapper .cms-content {
  border: 1px solid #5282f0;
  border-radius: 5px;
}
.policy-wrapper .cms-header {
  padding: 15px;
  border-bottom: solid 1px #5282f0;
  background-color: #5282f0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cms-header h2 {
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  color: #fff;
  letter-spacing: 0.5px;
} */

/* .content-description {
  height: calc(100vh - 181px);
  padding: 15px 15px 7px;
  background: rgba(82, 130, 240, 0.16);
  overflow-y: auto;
} */
/* .content-main {
  padding-bottom: 17px;
}
.content-main:last-child {
  padding-bottom: 0;
}
.content-description p {
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.4;
  color: #5282f0;
} */
.password-row .MuiOutlinedInput-input {
  padding-right: 68px;
}
.password-col .MuiOutlinedInput-input {
  padding-right: 68px;
}

/* filter-section */
.filter-area {
  padding: 15px;
  border: 1px solid #0091b2;
  border-radius: 5px;
}
.filter-top-content {
  display: flex;
  margin-bottom: 15px;
}
.search-box {
  width: calc(100% - 185px);
  margin-right: 15px;
  position: relative;
}
.select-box {
  width: 170px;
}
.search-box.search input {
  padding-right: 50px;
}
/* search-loader */
.search-box .search-loader {
  position: absolute;
  top: 50%;
  right: 14px;
  transform: translateY(-50%);
}

.filter-bottom-content {
  display: flex;
  flex-wrap: wrap;
}
.filter-bottom-content .select-box:not(:last-child) {
  margin-right: 15px;
}
.filter-area input {
  font-size: 14px;
}
.select-box .form-label {
  margin-bottom: 8px;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0.3px;
  color: #00536c;
}
/* fiter-select/input-field style */
.filter-area .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input {
  font-size: 14px;
  padding: 14px !important;
  font-family: "soleilregular";
}
.btn-area {
  padding-top: 15px;
  display: flex;
}
.btn-area button:not(:last-child) {
  margin-right: 15px;
}
.reset-btn button:hover {
  background-color: #d32f2f;
  color: #fff;
  border-color: transparent;
}

/* table-section */
.main-section-wrap {
  padding-top: 25px;
}

/* date-picker-styling */
.MuiPickersDay-today.MuiDateRangePickerDay-notSelectedDate {
  border: 1px solid rgba(0, 130, 240, 0.6);
}
.MuiPickersDay-today.MuiDateRangePickerDay-notSelectedDate:hover {
  background-color: rgba(0, 145, 178, 0.04);
}
.MuiPickersDay-root.MuiPickersDay-dayWithMargin.MuiPickersDay-today {
  border: 1px solid rgba(0, 145, 178, 0.6);
  background-color: rgba(0, 145, 178, 0.04);
}
.MuiPickersDay-root.MuiPickersDay-dayWithMargin.MuiPickersDay-today:hover {
  border: 1px solid rgba(0, 145, 178, 0.6);
  background-color: rgba(0, 145, 178, 0.04);
}
.MuiButtonBase-root.MuiPickersDay-root.Mui-selected {
  background-color: #0091b2 !important;
}
.MuiPaper-root {
  box-shadow: 0px 5px 5px -3px rgb(82 130 240 / 20%), 0px 8px 10px 1px rgb(82 130 240 / 14%),
    0px 3px 14px 2px rgb(82 130 240 / 12%);
}
.MuiPaper-root.MuiPopover-paper {
  box-shadow: 0px 5px 5px -3px rgb(82 130 240 / 20%), 0px 8px 10px 1px rgb(82 130 240 / 14%),
    0px 3px 14px 2px rgb(82 130 240 / 12%);
}
.date-picker .MuiSvgIcon-root {
}
/* image-upload-secton */
.upload-wrap {
  display: flex;
  height: 100%;
}
.upload-image-2 {
  width: 150px;
  height: 150px;
  border-radius: 5px;
  border: 1px dashed #00536c;
  /* padding: 4px; */
  flex: 0 0 150px;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  background-color: #f2f2f2;
}
.upload-image-2 figure,
.upload-image-2 figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.upload-image-2 .upload-icon {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}
.upload-image-2 .close-icon {
  position: absolute;
  top: 8px;
  right: 8px;
}
.close-icon .MuiSvgIcon-root {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #fff;
  color: red;
  transition: all 0.3s ease-in;
  cursor: pointer;
}
.close-icon:hover svg {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.upload-icon svg {
  padding: 4px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #d8d8d8;
  color: #4ac1e0;
  transition: all 0.3s ease-in;
}
.upload-icon:hover svg {
  padding: 4px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.upload-file {
  width: 150px;
  height: 150px;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  background-color: #f2f2f2;
  border: 1px dashed #00536c;
}
.upload-file input {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  z-index: 9;
  cursor: pointer;
}
.upload-file .upload-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.upload-file .upload-icon figure {
  width: 50px;
  height: 50px;
  margin: 0 auto 10px;
}
.upload-file .upload-icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.upload-file p {
  margin-bottom: 15px;
  font-size: 14px;
  white-space: nowrap;
}
.success-msg {
  width: 100%;
  margin-top: 5px;
  display: inline-block;
  font-size: 13px;
  color: green;
  text-align: left;
}

/* loader-styling */
.btn-wrap .MuiCircularProgress-svg {
  color: rgba(168, 192, 247, 0.7);
}
.content-loader .MuiCircularProgress-svg {
  color: #0091b2;
}
.btn-wrap .MuiCircularProgress-root {
  margin-right: 10px;
}
.btn-wrap .MuiButtonBase-root.MuiButton-root:disabled {
  color: rgba(168, 192, 247, 0.7);
}
.content-active {
  display: none;
}
.content-inactive {
  display: show;
}

/* list-loader-component */
.table-wrapper {
  position: relative;
}
